@font-face {
    font-family: 'DMSans';
    src: url('./fonts/DMSans.ttf') format('ttf');
}

body {
    font-family: 'DMSans', sans-serif;
    font-size: 15px;
    line-height: 1.42;
    background-color: #FFFFFF;
    color: #000000;
    margin-left: -2%;
    padding: 0;
}

body[data-theme="dark"] {
    background-color: #000000;
    color: #FFFFFF;
}

.container {
    max-width: 470px;
    margin: 0 auto;
    margin-top: 6.5%;
    margin-bottom: 4%;
    display: block;   
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.navbar a {
    color: #BFBFBF;
    text-decoration: none;
    transition: color 0.3s;
    margin-right: 24px;
    font-weight: 500;
}

body[data-theme="dark"] .navbar a {
    color: #A6A6A6;
}

.navbar a:hover {
    color: #333;
}

body[data-theme="dark"] .navbar a:hover {
    color: #FFFFFF;
}

.navbar a.active {
    color: #000;
}

body[data-theme="dark"] .navbar a.active {
    color: #FFFFFF;
}

.toggle-container {
    display: flex;
    align-items: center;
}
  
.custom-eclipse-toggle {
    font-size: 1.45em;
    color: #000000;
}

body[data-theme="dark"] .custom-eclipse-toggle {
    color: #FFFFFF;
}

.toggle-theme {
    font-size: 1em;
    cursor: pointer;
    color: #000000;
}

body[data-theme="dark"] .toggle-theme {
    color: #FFFFFF;
}

.toggle-theme:hover {
    color: #333;
}

body[data-theme="dark"] .toggle-theme:hover {
    color: #CCCCCC;
}

p {
    font-family: 'DMSans', sans-serif;
    font-weight: 300;
    color: #000000;
    margin-bottom: 10px;
}

body[data-theme="dark"] p {
    color: #FFFFFF;
} 

a {
    color: #000000;
    text-decoration: none;
}

body[data-theme="dark"] a {
    color: #FFFFFF;
}

a:hover {
    color: #666;
    transition: color 0.3s;
}

body[data-theme="dark"] a:hover {
    color: #BFBFBF;
}

.fa-square-arrow-up-right {
    font-size: smaller;
    color: #666;
    vertical-align: -0.1em;
}

body[data-theme="dark"] .fa-square-arrow-up-right {
    color: #999;
}

.section-header {
   display: flex;
   justify-content: space-between;
   align-items: center;
}
  
.link-underline { 
    text-decoration: underline;
}

.icon-link {
    text-decoration: none;
    margin-right: 18px;
    font-size: 1.5em;
}

.source-link {
    color: #808080;
    font-size: 14px;
}

body[data-theme="dark"] .source-link {
    color: #A6A6A6;
}

.source-link + i {
    font-size: smaller;
    color: #808080;
}

body[data-theme="dark"] .source-link + i {
    color: #A6A6A6;
}

.proj-link {
    font-size: smaller;
    color: #808080;
}

body[data-theme="dark"] .proj-link {
    color: #A6A6A6;
}

hr {
    margin-top: 20px;
    margin-bottom: 20px;
    opacity: 0.2;
}

.divider {
    margin: 0;
}

.dark-grey {
    color: #444;
}

body[data-theme="dark"] .dark-grey {
    color: #FFFFFF;
}

.row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    margin: 15px 0;
} 

.note {
    flex-basis: 65%;
    margin: 0;
}

.temp {
    flex-basis: 100%;
}

.date, .website {
    flex-basis: 35%;
    text-align: right;
}

.website {
    font-size: 14px;
    color: #666;
}
  
.section {
    margin: 25px 0 ;
}
  
.section-title {
    font-weight: 500;
    margin-bottom: 20px;
}
  
.website {
    margin: 0;
    color: #555;
}

body[data-theme="dark"] .website {
    color: #A6A6A6;
}

@media only screen and (max-width: 600px) {
    .container {
        width: 80%;
        padding: 10px;
        margin-top: 12%;
        margin-bottom: 4%;
    }

    body {
        font-size: 15px;
    }

    .navbar div a {
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
    }

    .navbar a {
        margin-right: 18px;
    }
}

@media only screen and (min-width: 1600px) {
    .container {
        max-width: 620px;
    }
}
